import React, { useEffect, useState } from "react";
import DiscoverLuxHeader from "../../components/DiscoverLux/Header";
import Layout from "../../components/Layout";
import { appendImage, createImage, createMarkup } from "../../utils";
import axios from "axios";
import { apiRoutes } from "../../constants/apiRoutes";
import { isMobile } from "react-device-detect";
// import { Helmet } from "react-helmet";
import Helmet from "../../components/Helmet";
import routes from "../../components/constants/routes";

const DLRegister = () => {
  const [header, setHeader] = useState(undefined);
  const [registerData, setRegisterData] = useState({});
  const fetchRegister = async () => {
    try {
      const constactres = await axios.get(apiRoutes.register);
      setRegisterData(constactres.data.data.attributes);
    } catch (error) {}
  };

  const fetchHeader = async () => {
    try {
      const { data } = await axios.get(apiRoutes.header);
      if (data) {
        setHeader(data.data.attributes);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchHeader();
    fetchRegister();
  }, []);

  useEffect(() => {
    appendImage("rich-editor");
  }, [registerData]);

  const formHeightWeb = registerData?.discoverLux_formHeightWeb ?? "250";
  const formHeightMob = registerData?.discoverLux_formHeightMob ?? "250";

  const seoData = {
    title: "Register for Discover Luxembourg | Join City Exploration",
    description:
      "Register for Discover Luxembourg to join city exploration events. Gather your team, solve challenges, and experience the city's culture, history, and technology firsthand.",
    url: `${process.env.GATSBY_SITE_URL}${routes.DLRegister}/`,
  };

  return (
    <Layout>
      <>
        <Helmet
          title={seoData.title}
          description={seoData.description}
          url={seoData?.url}
        />

        <div className="discover-luxembourg">
          <div className="container-fluid p-mob">
            <div className="row">
              <div className="col-md-12">
                <div className="hero-section">
                  <img
                    src={createImage(
                      header?.discover_luxembourg_image?.data?.attributes?.url
                    )}
                    alt="The Discover Luxembourg "
                    width={"auto"}
                    height={"auto"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="head-tab mt-40">
            <DiscoverLuxHeader />

            <>
              {" "}
              <div className="container">
                <div
                  className="mt-4 rich-editor"
                  dangerouslySetInnerHTML={createMarkup(
                    registerData?.discover_luxmebourg_description
                  )}
                ></div>
              </div>
              <div className="film-register mt-40">
                <iframe
                  id="JotFormIFrame-232740555428458"
                  title="Competition Entry Form"
                  onload="window.parent.scrollTo(0,0)"
                  allowtransparency="true"
                  allowfullscreen="true"
                  allow="geolocation; microphone; camera"
                  src={registerData?.registration_discover_luxmebourg}
                  frameborder="0"
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    height: isMobile
                      ? `${formHeightMob}vh`
                      : `${formHeightWeb}vh`,
                    border: "none",
                  }}
                  scrolling="no"
                ></iframe>
              </div>
            </>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default DLRegister;
